<template>
  <v-container>
    <page-title title="常用購物清單" />

    <p class="pb-2 border-bottom-1 mb-2">請勾選常用品項加入購物車</p>
    <v-divider/>

    <div class="d-flex align-center pt-2">
      <v-checkbox
          v-model="isSelectAll"
          dense
          hide-details
      ></v-checkbox>
      <span>全選</span>
    </div>

    <div class="mb-10" v-if="data">
      <favorite-card
          v-for="(item, index) in cardData"
          :key="index"
          v-model="cardData[index]"
          :configs="cardConfigs(index)"
      ></favorite-card>
    </div>

    <v-btn
        v-if="showCart"
        width="100%"
        @click="addToCart"
        class="primary"
    >
      加入購物車
    </v-btn>

  </v-container>
</template>

<script>
import discountMixins from "@/mixins/discount.js"
import countMixins from "@/mixins/count"
export  default  {
  mixins: [discountMixins, countMixins],
  components: {
    'favorite-card': () => import('@/modules/base/components/priceCard/favorite/favoriteCard')
  },
  data: () => ({
    data: null,
    cardData: null,
  }),
  computed: {
    storeId() {
      return this.$store.getters['member/storeId']
    },
    providerId() {
      return this.$route.params.providerId
    },
    showCart() {
      if(!this.data) return false
      return this.data.length > 0
    },
    isCheckedList() {
      if(!this.cardData) return []
      return this.cardData
          .filter(data => data.checked)
    },
    favorites() {
      if(!this.isCheckedList) return []
      return this.isCheckedList
          .map((item,index) => {
            return {
              quantity: this.$helper.transformShippingCount({count: item.order_count, unit: item.shipping_unit}),
              product_id: item.product_id
            }
          })
    },
    isSelectAll: {
      get() {
        if(!this.cardData) return false
        return this.cardData.filter(item => item.checked).length == this.cardData.length
      },
      set(value) {
        this.cardData.forEach(item => item.checked = value)
      }
    },
  },
  watch: {
    data: {
      deep: true,
      async handler() {
        this.cardData = this.data.map(item => {
          const price = this.getDiscountPrice({
            discount_config: item.discount_config || [],
            price: item.price,
            quantity: item.count
          })

          return {
            product_id: item.id,
            shipping_unit: item.shipping_unit, // 出貨單位
            calculate_unit: item.calculate_unit, // 計價單位
            per_shipping_count: item.per_shipping_count, //  ex: 規格:每籃5包
            // shipping_count: this.$helper.reverseShippingCount(item.count, item.shipping_unit), // 出貨數量
            price: price, // 單價
            order_count: this.$helper.reverseShippingCount(item.count, item.shipping_unit), // 訂購數量 / 數量,
            checked: false, // checkbox
          }
        })
      }
    },
  },
  mounted() {
    this.$root.gaLogEvent('商店_常用購物清單頁')
  },
  async created() {
    await this.getFavoriteData()
  },
  methods: {
    async getFavoriteData() {
      this.$store.dispatch('loading/active')
      try {
        const res = await this.$api.collection.favoriteApi.index(this.storeId, this.providerId)
        // console.log(res)
        this.data = res.map(item => {
          return {
            ...item.product,
            count: item.count
          }
        })
      } catch(err) {
        console.error(err)
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    cardConfigs(index) {
      if(!this.data) return []
      const item = this.data[index]
      const configs = [
        {
          label: item.name,
          colon: false,
          class: 'justify-space-between',
          checkbox: {
            show: true,
          },
          favorite: {
            status: true,
            meta: {
              storeId: this.storeId,
              providerId: this.providerId,
              productId: item.id
            },
            callback: () => {
              this.data.splice(index, 1)
            }
          }
        },
        { label: "單價", id: 'price', col: 12 },
        {
          id: "order_count",
          label: "訂購數量",
          type: "number-input",
          fixed: 2,
          name: `items[${index}][quantity]`,
          onChange: (value) => this.onChange(index),
          rule: (count) => this.countRule({count, unit: item.shipping_unit}),
          col: 12,
          max:"999999.99"
        }
      ]

      return configs
    },
    async onChange(index) {
      this.$store.dispatch("loading/active");
      try {
        const productId = this.data[index].id
        const params = {
          count: this.$helper.transformShippingCount(
              {count: this.cardData[index].order_count, unit: this.cardData[index].shipping_unit}
          )
        }
        await this.$api.collection.favoriteApi.update(this.storeId, this.providerId, productId, params)
      } catch(err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
      console.log(index)
    },
    async addToCart() {
      if(this.favorites.length == 0) {
        this.$snotify.warning('請選擇商品')
        return;
      }
      this.$root.gaLogEvent('商店_點擊_加入購物車')
      this.$store.dispatch("loading/active");
      try {
        const params = {
          products: this.favorites,
        };
        await this.$api.collection.cartApi.add(
            this.storeId,
            this.providerId,
            params
        );
        this.$snotify.success("已加入購物車");
        this.$router.push({
          name: 'cart',
          params: {
            providerId: this.providerId
          }
        })
      } catch (err) {
        console.error(err);
        this.$snotify.error("加入購物車失敗");
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
  },
}
</script>